<template>
  <v-row>
    <v-col cols="12" lg="12" class="pb-0">
      <v-radio-group
        v-model="selectSoftSwitchInput"
        @change="getSoftswitchType"
        class="mt-0 py-3"
      >
        <v-radio value="new">
          <template v-slot:label>
            <div class="black--text">New</div>
          </template>
        </v-radio>
        <v-radio value="existing">
          <template v-slot:label>
            <div class="black--text">Existing</div>
          </template>
        </v-radio>
      </v-radio-group>
      <span v-show="showSoftswitchInput">
        <v-form v-model="isFormValid">
          <v-text-field
            v-model="getSelectSoftSwitchData.server_name"
            label="Server Name"
            append-icon="person"
            outlined
            rounded
            dense
          ></v-text-field>
          <v-text-field
            v-model="getSelectSoftSwitchData.ip"
            :rules="ipSwitchRules"
            label="Ip"
            append-icon="person"
            outlined
            rounded
            dense
          ></v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="px-4"
              dark
              @click="closeAssignNumberModal"
              outlined
            >
              Cancel
            </v-btn>
            <v-btn
              class="px-4 ml-4 primary-btn"
              outlined
              :loading="isLoading"
              @click="addConnectivityServersData"
              :disabled="!isFormValid"
            >
              Assign
            </v-btn>
          </v-card-actions>
        </v-form>
      </span>
      <span v-show="showSoftswitchList">
        <v-select
          :items="sipUriDeviceData"
          v-model="softSwitchData.server_name"
          item-text="server_name"
          item-value="auth.ip"
          label="Select Softswitch"
          @change="getSelectSoftSwitch"
          append-icon="person"
          return-object
          outlined
          rounded
          dense
        >
        </v-select>
        <v-text-field
          v-model="getSelectSoftSwitchData.server_name"
          label="Server Name"
          append-icon="person"
          outlined
          rounded
          disabled
          dense
        ></v-text-field>
        <v-text-field
          v-model="getSelectSoftSwitchData.ip"
          label="Ip"
          append-icon="person"
          outlined
          rounded
          disabled
          dense
        ></v-text-field>
        <v-divider></v-divider>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="px-4"
            outlined
            @click="closeAssignNumberModal"
            dark
          >
            Cancel
          </v-btn>
          <v-btn
            class="px-4 ml-4 primary-btn"
            outlined
            :loading="isLoading"
            @click="assignDidToServersData"
          >
            Assign
          </v-btn>
        </v-card-actions>
      </span>
    </v-col>
  </v-row>
</template>

<script>
import numberManagerAPI from "@/services/numberManagerAPI.js";

export default {
  props: ["sipUriDeviceData", "assigningNumberData"],
  data() {
    return {
      selectSoftSwitchInput: "existing",
      isLoading: false,
      getSelectSoftSwitchData: {},
      showSoftswitchList: true,
      showSoftswitchInput: false,
      isFormValid: false,
      ipSwitchRules: [
        // (v) =>
        //   /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
        (value) => {
          const forbiddenChars = ["@", "/", "\\"]; // Characters to disallow

          if (
            forbiddenChars &&
            forbiddenChars.some((char) => value && value.includes(char))
          ) {
            this.isFormValid = true;
            return "Please enter a valid text without @, /, or \\ characters.";
          }

          if (!value) {
            this.isFormValid = false;
          }

          return true; // Validation passed
        },
      ],
      userDetail: {},
      softSwitchData: {},
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
  },
  methods: {
    setValue: function () {
      this.getSelectSoftSwitchData = {};
      this.softSwitchData = {};
    },
    closeAssignNumberModal() {
      this.getSelectSoftSwitchData = {};
      this.softSwitchData = {};
      this.$emit("close-asign-modal");
    },
    getSelectSoftSwitch() {
      this.getSelectSoftSwitchData = {
        server_name: this.softSwitchData.server_name.server_name,
        ip: this.softSwitchData.server_name.auth.ip,
      };
    },
    getSoftswitchType() {
      if (this.selectSoftSwitchInput == "new") {
        this.showSoftswitchInput = true;
        this.showSoftswitchList = false;
        this.getSelectSoftSwitchData = {};
      }
      if (this.selectSoftSwitchInput == "existing") {
        this.showSoftswitchInput = false;
        this.showSoftswitchList = true;
        this.getSelectSoftSwitchData = {};
        this.softSwitchData = {};
      }
    },
    async assignDidToServersData() {
      if (
        this.getSelectSoftSwitchData.server_name &&
        this.getSelectSoftSwitchData.ip
      ) {
        this.isLoading = true;
        var didToServerObj = {
          accountId: this.userDetail.accountId,
          authToken: this.userDetail.authToken,
          inventoryItemId: this.assigningNumberData.inventoryItemId,
          ip: this.getSelectSoftSwitchData.ip,
          serverName: this.getSelectSoftSwitchData.server_name,
        };
        try {
          let response = await numberManagerAPI.assignDidToServers(
            didToServerObj
          );
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.responseMessage,
            color: "success",
          });
          this.$emit("closedAssignedModal");
          // this.closeAssignNumberModal();
          this.isLoading = false;
        } catch (error) {
          // console.log("====error===", error);
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong!",
            color: "error",
          });
          this.isLoading = false;
        }
      } else {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Fill the fields!",
          color: "error",
        });
      }
    },
    async addConnectivityServersData() {
      if (
        this.getSelectSoftSwitchData.server_name &&
        this.getSelectSoftSwitchData.ip
      ) {
        this.isLoading = true;
        var didToServerObj = {
          accountId: this.userDetail.accountId,
          authToken: this.userDetail.authToken,
          inventoryItemId: this.assigningNumberData.inventoryItemId,
          ip: this.getSelectSoftSwitchData.ip,
          serverName: this.getSelectSoftSwitchData.server_name,
        };
        try {
          let response = await numberManagerAPI.addConnectivityServers(
            didToServerObj
          );
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.responseMessage,
            color: "success",
          });
          this.$emit("closedAssignedModal");
          // this.closeAssignNumberModal();
          this.isLoading = false;
        } catch (error) {
          // console.log("====error===", error);
          this.isLoading = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong!",
            color: "error",
          });
        }
      } else {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Fill the fields!",
          color: "error",
        });
      }
    },
  },
};
</script>